import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { AuthService } from "../../models/Auth/auth.service";
import { UsersService } from "../../models/usersInfo/users.service";
import { Role } from "../../models/usersInfo/role"
import { environment } from 'environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { Subscription, forkJoin, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { element } from '@angular/core/src/render3';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'cat-menu-left-celic',
  templateUrl: './menu-left-celic-vertical.component.html',
  providers: [UsersService],
})

export class MenuLeftComponentCelic implements OnInit, OnDestroy {

  public MAIN_SCHOOL_WHITE_LOGO: any = environment.MAIN_SCHOOL_WHITE_LOGO;
  public SCHOOL_NAME: string = environment.SCHOOL_NAME;
  public showNav: boolean = false;
  userId: number;
  private _subscription: Subscription = new Subscription();
  public isSubmenu = false;
  public isSubmenu2 = false;
  public menu: any[] = []
  public roles: Role[] = [];

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _authService: AuthService,
    private _servicios: UsersService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._subscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUser = parseInt(localStorage.getItem('currentUser'));
        this.loadRoles(currentUser);
        this.LoadAnime();
      }
    });



  }


  private loadRoles(userId: number) {
    let tempMenu = []
    const filterUser = {
      include: [
        { roles: { Capabilities: { relation: "PlatformModule", scope: { where: { and: [{ showInMenu: true }] } } } } },
        "MilitarInfos", "TypeUser", "ContactInfos", "UserDocuments", "ProfesionalProfile", 'ClassificationUser', 'WorkInfos', 'AcademicInfos'],
    }

    this._servicios.getInfoUserNew(userId, JSON.stringify(filterUser)).subscribe(data => {
      this.roles = data.roles

      // flat cant not be used, mixing of reduce and concat is implemented instead
      const capabilities = data.roles.map(x => x.Capabilities)
      let flatCapabilities = capabilities.reduce(
        (arr, elem) => arr.concat(elem), []
      )
      const platformModules = flatCapabilities.map(x => x.PlatformModule).filter(item => item)
      let groups = platformModules.map(x => x.groupName).reduce(
        (arr, elem) => arr.concat(elem), []
      )
      groups = Array.from(new Set(groups)).sort();

      groups.forEach(element => { tempMenu.push({ groupName: element }) })

      tempMenu.forEach(menuItem => {
        menuItem.routes = platformModules.filter(x => x.groupName == menuItem.groupName).sort((a, b) => a.linkName.localeCompare(b.linkName));

      })
      this.menu = [...tempMenu]
    })

  }

  checkRoles(roleName): boolean {
    return this.roles.find(x => x.name === roleName) ? true : false

  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }


  LoadAnime() {
    $(function () {

      // scripts for "menu-left" module

      /////////////////////////////////////////////////////////////////////////////////////////
      // add backdrop

      $('.cat__menu-left').after('<div class="cat__menu-left__backdrop cat__menu-left__action--backdrop-toggle"><!-- --></div>');

      /////////////////////////////////////////////////////////////////////////////////////////
      // submenu

      $('.cat__menu-left__submenu > a').on('click', function () {

        if ($('body').hasClass('cat__config--vertical') || $('body').width() < 768) {

          var parent = $(this).parent(),
            opened = $('.cat__menu-left__submenu--toggled');

          if (!parent.hasClass('cat__menu-left__submenu--toggled') && !parent.parent().closest('.cat__menu-left__submenu').length)
            opened.removeClass('cat__menu-left__submenu--toggled').find('> .cat__menu-left__list').slideUp(200);

          parent.toggleClass('cat__menu-left__submenu--toggled');
          parent.find('> .cat__menu-left__list').slideToggle(200);

        }

      });

      // remove submenu toggle class when viewport back to full view
      $(window).on('resize', function () {
        if ($('body').hasClass('cat__config--horizontal') || $('body').width() > 768) {
          $('.cat__menu-left__submenu--toggled').removeClass('cat__menu-left__submenu--toggled').find('> .cat__menu-left__list').attr('style', '');
        }
      });


      /////////////////////////////////////////////////////////////////////////////////////////
      // custom scroll init

      if ($('body').hasClass('cat__config--vertical')) {

      }


      /////////////////////////////////////////////////////////////////////////////////////////
      // toggle menu



      $('.cat__menu-left__action--menu-toggle').on('click', function () {
        if ($('body').width() < 768) {
          $('body').toggleClass('cat__menu-left--visible--mobile');
        } else {
          $('body').toggleClass('cat__menu-left--visible');
        }
      })

      $('.cat__menu-left__action--backdrop-toggle').on('click', function () {
        $('body').removeClass('cat__menu-left--visible--mobile');
      })


      /////////////////////////////////////////////////////////////////////////////////////////
      // colorful menu

      var colorfulClasses = 'cat__menu-left--colorful--primary cat__menu-left--colorful--secondary cat__menu-left--colorful--primary cat__menu-left--colorful--default cat__menu-left--colorful--info cat__menu-left--colorful--success cat__menu-left--colorful--warning cat__menu-left--colorful--danger cat__menu-left--colorful--yellow',
        colorfulClassesArray = colorfulClasses.split(' ');

      function setColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').each(function () {
          var randomClass = colorfulClassesArray[Math.floor(Math.random() * colorfulClassesArray.length)];
          $(this).addClass(randomClass);
        })
      }

      function removeColorfulClasses() {
        $('.cat__menu-left__list--root > .cat__menu-left__item').removeClass(colorfulClasses);
      }

      if ($('body').hasClass('cat__menu-left--colorful')) {
        setColorfulClasses();
      }

      $('body').on('setColorfulClasses', function () {
        setColorfulClasses();
      });

      $('body').on('removeColorfulClasses', function () {
        removeColorfulClasses();
      });




    });

  }

}
