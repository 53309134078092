import { schoolToLoad } from "./environment.common";

export const environment = {
  production: true,
  PRINCPAL_APP_NAME: 'cedoc',
  UrlAPI: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/api',
  URL_BACK_V2: 'https://test-default-dot-cedoc-360c.ue.r.appspot.com/api',
  Url: 'https://test-user-dot-cedoc-360c.ue.r.appspot.com/',
  dialogflow: {
    saraBot: "1e9f879b83eb40bc9a4be925f9274604",
  },
  urlDialogFlowCloudFunction: 'https://us-central1-cedoc-360c.cloudfunctions.net/',

  ...schoolToLoad()
};
